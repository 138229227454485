<template>
    <div>
      <h2>Данные для первого входа</h2>
      <el-row :gutter="10">
          <el-col :span="10"> <!-- Отход -->
            <el-select
              @change="getOrgs();"
              clearable
              class="searchPole"
              v-model="search.waste"
              filterable
              remote
              reserve-keyword
              placeholder="Отход"
            >
              <el-option
                v-for="item in wastes"
                :key="item.organizationWasteId"
                :label="item.organizationInfo.name +'-'+item.ss01WasteDictionaryDTO.wasteCode+ '/'+item.ss01WasteDictionaryDTO.wasteName"
                :value="item.organizationWasteId"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="10"> <!-- Организация -->
            <el-select
              :disabled="search.waste == null"
              clearable
              class="searchPole"
              v-model="search.type"
              filterable
              remote
              reserve-keyword
              placeholder="Организация"
              @change="reload()"
              
            >
              <el-option
                v-for="item in orgsList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4"> <!-- Обновить -->
              <el-button @click="reload()">
                  Обновить
              </el-button>
          </el-col>
      </el-row>
      <el-row :gutter="2">
          <el-col :span="2">ID</el-col>
          <el-col :span="4">Тип</el-col>
          <el-col :span="7">Значение</el-col>
          <el-col :span="5">Параметры</el-col>
          <el-col :span="6"></el-col>
      </el-row>
      <el-row v-for="(l,index) in firstEncounters" :key="index">
        
          <el-divider></el-divider>
          <el-col :span="2" style="height: 40px;">{{ l.id != null ? l.id : " " }}</el-col>

          <el-col :span="4">
            <el-select
              :disabled="search.waste == null"
              clearable
              class="searchPole"
              v-model="l.action_id"
              filterable
              remote
              reserve-keyword
              placeholder="Тип операции"
              
            >
              <el-option
                v-for="item in fetype"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-row>{{l.action_id == 3 ? "По импорту" : (l.action_id==5 ? "По экспорту" : "")}}</el-row>
          </el-col>

          <el-col :span="5">
              <el-input
                  placeholder="Значение"
                  v-model="l.value"
                ></el-input>
                <el-input v-if="l.action_id==3"
                  placeholder="По импорту"
                  v-model="l.valueImportExport"
                ></el-input>
                <el-input v-if="l.action_id==5"
                  placeholder="По экспорту"
                  v-model="l.valueImportExport"
                ></el-input>
          </el-col>
          <el-col :span="2" >
            <el-row style="height: 40px;">
              {{wastes.find((element) => element.organizationWasteId == search.waste).pss93UnitsInfo.name}}
            </el-row>
            <el-row style="height: 40px;" v-if="l.action_id == 3 || l.action_id==5">
              {{wastes.find((element) => element.organizationWasteId == search.waste).pss93UnitsInfo.name}}
            </el-row>
          </el-col>
          <el-col :span="5" style="height: 40px;">

            <el-select
              v-if="l.action_id==3"
              clearable
              class="searchPole"
              v-model="l.pss17_receipt_reasons_code"
              filterable
              remote
              reserve-keyword
              placeholder="Наименование причины поступления"
            >
            <el-option
                v-for="item in pss17ReceiptReasonInfoList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>

            <el-select
              v-if="l.action_id==4"
              clearable
              class="searchPole"
              v-model="l.pss17_receipt_reasons_code"
              filterable
              remote
              reserve-keyword
              placeholder="Наименование причины поступления от ф.з."
            >
            <el-option
                v-for="item in pss17ReceiptReasonInfoList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>

            <el-select
              v-if="l.action_id==5"
              clearable
              class="searchPole"
              v-model="l.pss18_transfer_reasons_code"
              filterable
              remote
              reserve-keyword
              placeholder="Причина операции передачи"
            >
            <el-option
                v-for="item in pss18TransferReasonInfoList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>

            <el-select
              v-if="l.action_id==5 && l.pss18_transfer_reasons_code == 6"
              clearable
              class="searchPole"
              v-model="l.pss21_transfer_sites_code"
              filterable
              remote
              reserve-keyword
              placeholder="Объект захоронения"
            >
            <el-option
                v-for="item in pss21BurialObjectInfoList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>

            <el-select
              v-if="l.action_id==6"
              clearable
              class="searchPole"
              v-model="l.pss18_transfer_reasons_code"
              filterable
              remote
              reserve-keyword
              placeholder="Причина операции передачи"
            >
            <el-option
                v-for="item in pss18TransferReasonInfoList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>

            <el-select
              v-if="l.action_id==7"
              clearable
              class="searchPole"
              v-model="l.pss19_directions_of_use_code"
              filterable
              remote
              reserve-keyword
              placeholder="Наименование направления использования"
            >
            <el-option
                v-for="item in pss19DirectionOfUseInfoList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>

            <el-select
              v-if="l.action_id==8"
              clearable
              class="searchPole"
              v-model="l.pss20_neutralize_methods_code"
              filterable
              remote
              reserve-keyword
              placeholder="Наименование способа обезвреживания"
            >
            <el-option
                v-for="item in pss20NeutralizeMethodInfoList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>

            <el-select
              v-if="l.action_id==9"
              clearable
              class="searchPole"
              v-model="l.pss21_burial_sites_code"
              filterable
              remote
              reserve-keyword
              placeholder="Объект захоронения"
            >
            <el-option
                v-for="item in pss21BurialObjectInfoList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>

            <el-select
              v-if="l.action_id==10"
              clearable
              class="searchPole"
              v-model="l.pss22_store_sites_code"
              filterable
              remote
              reserve-keyword
              placeholder="Наименование объекта хранения"
            >
            <el-option
                v-for="item in pss22StoredObjectInfoList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>

          </el-col>
          <el-col :span="4">
              <el-button @click="save(l)" :disabled="prod==1" >
                  Сохранить
              </el-button>
              <el-button @click="delet(l)" :disabled="prod==1" >
                  Удалить
              </el-button>
          </el-col>
      </el-row>
      <el-row>
          <el-col :span="10">{{ " " }}</el-col>
          <el-col :span="4"> 
              <el-button @click="create()" :disabled="prod==1" >
                  Создать
              </el-button>
          </el-col>
          <el-col :span="10">{{ " " }}</el-col>
      </el-row>
          
  
    </div>
  </template>
  <script>
  
  import { AXIOS } from "@/AXIOS/http-common";
  import Cookies from "js-cookie";
  export default {
    name: "firstEncounterMain",
    data() {
      return {
        prod:0,
        
          firstEncounters:[],
          orgsList:[],
          wastes:[{id:null,organizationInfo:{name:"Объект к которому прявязан отход"},ss01WasteDictionaryDTO:{wasteName:"Отход",code:"Код отхода"},organizationWasteId:null,pss93UnitsInfo:{name:null}}],
          search:{type:null,waste:null},
          fetype: [
              {id:1,name:"Хранилось на начало года"},
              {id:2,name:"Образовано"},
              {id:3,name:"Поступило"},
              {id:4,name:"Поступило от физ. лиц."},
              {id:5,name:"Передано"},
              {id:6,name:"Реализовано физ. лицам"},
              {id:7,name:"Использовано"},
              {id:8,name:"Обезврежено"},
              {id:9,name:"Захоронено"},
              {id:10,name:"Направлено на объекты хранения"},
          ],
          defaultProps: {
          children: "children",
          label: "name",
          },
          pss17ReceiptReasonInfoList: null,
          pss18TransferReasonInfoList: null,
          pss19DirectionOfUseInfoList: null,
          pss20NeutralizeMethodInfoList: null,
          pss21BurialObjectInfoList: null,
          pss22StoredObjectInfoList: null,
      };
    },
    methods: {
      getPss17ReceiptReasonInfoList() {
      this.getSubClassifierById(17).then((response) => {
        this.pss17ReceiptReasonInfoList = response.data.content;
        this.pss17ReceiptReasonInfoList.push({id:null,name:""});
      });
    },

    getPss18TransferReasonInfoList() {
      this.getSubClassifierById(18).then((response) => {
        this.pss18TransferReasonInfoList = response.data.content;
        this.pss18TransferReasonInfoList.push({id:null,name:""});
      });
    },

    getPss19DirectionOfUseInfoList() {
      this.getSubClassifierById(19).then((response) => {
        this.pss19DirectionOfUseInfoList = response.data.content;
        this.pss19DirectionOfUseInfoList.push({id:null,name:""});
      });
    },

    getPss20NeutralizeMethodInfoList() {
      this.getSubClassifierById(20).then((response) => {
        this.pss20NeutralizeMethodInfoList = response.data.content;
        this.pss20NeutralizeMethodInfoList.push({id:null,name:""});
      });
    },

    getPss21BurialObjectInfoList() {
      this.getSubClassifierById(21).then((response) => {
        this.pss21BurialObjectInfoList = response.data.content;
        this.pss21BurialObjectInfoList.push({id:null,name:""});
      });
    },

    getPss22StoredObjectInfoList() {
      this.getSubClassifierById(22).then((response) => {
        this.pss22StoredObjectInfoList = response.data.content;
        this.pss22StoredObjectInfoList.push({id:null,name:""});
      });
    },
    async getSubClassifierById(id) {
      let data = {
        id: id,
        size: 100,
        page: 0,
      };
      return await this.$store.dispatch(
        "getAllDataSubClassifierForSelect",
        data
      );
    },

    async getOrgs()
    {
      if (this.search.type!=0)
      this.reload();
      await AXIOS.get(
            "user/organization/hierarchyOrgByWaste?waste=" + this.search.waste
        ).then(async (response) => {
            this.orgsList=response.data;
        });
    },

      async reload()
      {
        await AXIOS.post("firstEncounter/get",{wasteID:this.search.waste,type:this.search.type}).then( (response) => {
            this.firstEncounters=response.data;
        });
      },
      async save(fea)
      {
        
        let fe={
          
            id:fea.id,
            action_id:fea.action_id,
            value:parseFloat((fea.value+'').replace(',','.')),
            valueImportExport:parseFloat((fea.valueImportExport+'').replace(',','.')),
            pss17_receipt_reasons_code:fea.pss17_receipt_reasons_code,
            pss18_transfer_reasons_code:fea.pss18_transfer_reasons_code,
            pss21_transfer_sites_code:fea.pss21_transfer_sites_code,
            pss19_directions_of_use_code:fea.pss19_directions_of_use_code,
            pss20_neutralize_methods_code:fea.pss20_neutralize_methods_code,
            pss21_burial_sites_code:fea.pss21_burial_sites_code,
            pss22_store_sites_code:fea.pss22_store_sites_code,
            firstEncounterOrg:[],
            organization_waste_id:this.search.waste,
            organization_id:this.search.type,
        };
        if(fe.id!=null)
          await AXIOS.post("firstEncounter/update",fe);
        else
          await AXIOS.post("firstEncounter/create",fe);
        this.reload();
      },
      async delet(fea)
      {
        let fe={
          
          id:fea.id,
          action_id:fea.action_id,
            value:parseFloat((fea.value+'').replace(',','.')),
            valueImportExport:parseFloat((fea.valueImportExport+'').replace(',','.')),
          pss17_receipt_reasons_code:fea.pss17_receipt_reasons_code,
          pss18_transfer_reasons_code:fea.pss18_transfer_reasons_code,
          pss21_transfer_sites_code:fea.pss21_transfer_sites_code,
          pss19_directions_of_use_code:fea.pss19_directions_of_use_code,
          pss20_neutralize_methods_code:fea.pss20_neutralize_methods_code,
          pss21_burial_sites_code:fea.pss21_burial_sites_code,
          pss22_store_sites_code:fea.pss22_store_sites_code,
          firstEncounterOrg:[],
          organization_waste_id:this.search.waste,
          organization_id:this.search.type,
      };
        if(fea.id!=null)
          await AXIOS.post("firstEncounter/delete",fe);
        this.reload();
      },
        create()
        {
            this.firstEncounters.push({id:null});
        },
    },
    async mounted() { 
      this.prod = Cookies.get('prod'); 
    this.getPss17ReceiptReasonInfoList();
    this.getPss18TransferReasonInfoList();
    this.getPss19DirectionOfUseInfoList();
    this.getPss20NeutralizeMethodInfoList();
    this.getPss21BurialObjectInfoList();
    this.getPss22StoredObjectInfoList();

    await AXIOS.get("organization-waste/list?page=" + 0 + "&size="+10000).then(
      (response) => {
        console.log("response.data");
        console.log(response.data.content);
        this.wastes=response.data.content.sort((a, b) => {
        const nameA = a.organizationInfo.name.toUpperCase()+a.ss01WasteDictionaryDTO.wasteName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.organizationInfo.name.toUpperCase()+b.ss01WasteDictionaryDTO.wasteName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
              });
            }
          
          );
      
    },
  }
  </script>