<template>
    <div>
      <first-encounter-main></first-encounter-main>
    </div>
  </template>
  
  <script>
  import FirstEncounterMain from "@/components/ecologist/firstEncounter/firstEncounterMain";
  export default {
    name: "FirstEncounterMainView",
    components: { FirstEncounterMain },
  };
  </script>
  
  <style scoped></style>
  